export default {
  apiUrl: 'https://backend-stage.estate.hart-digital.com',
  apiBovwUrl: 'https://bovw.srv.getfloorplan.com',
  apiControlPanel: 'https://panel.hart-digital.com',
  appUrl: 'https://backoffice-stage.estate.hart-digital.com',
  publicUrl: 'https://stage.getfloorplan.com/public_fp',
  appEnv: 'stage',
  widget: {
    logoUrlEn: 'https://getfloorplan.com/',
  },
  pusher: {
    appKey: 'c58c7bbf1f4039222764',
    appCluster: 'eu',
  },
  sentry: {
    environment: 'stage',
    dsn: 'https://c3e93a73dc4ee92a34a7ec24160467a4@sentry.hart-digital.com/2',
  },
  featureFlagsOverride: {
    isEnvironmentVisible: true,
    isBrandingVisible: true,
    isWidgetMaterialVisible: true,
    isGenerationCrmPlan3dResultHighVisible: true,
    isCustomersVisible: true,
    isDatasetSelectionPlansVisible: true,
    isCanGetPlansToWorkVisible: true,
    isHasPanoramaCheckboxVisible: true,
    isBetaTestingCheckboxVisible: true,
    isWaitDurationVisible: true,
    isWidgetLinkConstructorVisible: true,
    isSamePlansAiVisible: true,
    isPlanDescriptionAndRendersVisible: true,
    isNewPlanOptionsVisible: true,
    isProactiveModeVisible: false,
    isPortalModeVisible: true,
    isSingleFloorCuttingVisible: false,
    isRenovationCheckboxVisible: false,
    isUnrealAdminPanelVisible: true,
    isUnrealTopDownCountVisible: true,
    isPngLosslessFormatChoiceVisible: true,
  },
  yandexMetrika: {
    counterId: 97097353,
    initOptions: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  },
  waitDuration: 200,
};
